.contact-wrapper-col{
    background-color: red;
    color: white; 
    padding-top: 15px;
}

.contact-wrapper{
    margin-top: 10px;
}

.contact-wrapper  span{ 
    padding-right: 5px;
}


.bgimg{
    filter: saturate(130%);
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%; 

}

@media only screen and (max-width: 768px){

    .bgimg{
          object-fit: contain;
    }
  }
  
  
  