.product-slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #198754;
  padding-top: 20px;
}
/*#dc3545 #198754 #ffc107 */
.product-list {
  display: flex;
  justify-content: center; /* Center the items horizontally */
  margin: 0 -10px; /* Add negative margin to counter the padding in .product-item */
}
  
.product-list {
  display: flex;
  transition: transform 1.9s ease-in-out;
}
  
.product-list.slide {
  animation: slideAnimation 1.9s ease-in-out;
}
  
@keyframes slideAnimation {
  from {
    transform: translateX(0);
    }
  to {
    transform: translateX(-30%);
    }
  }

.product-item {
  flex: 0 0 25%; /* Each item takes up 30% of the container width */
  margin: 0 30px; /* Add a small margin on both sides */
  box-sizing: border-box;
  text-align: center;
  background-color: white;
  border: 0px solid #ccc; /* Add border around each item */
}
  
.product-item img {
  width: 12rem;
  height: 12rem;
  align-self: center;
}
  
.prev-btn, .next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  background-color: #fff;
  border: 0px solid #ccc;
  cursor: pointer;
}
  
.prev-btn {
  left: 0;
  font-size: 30px;
}
  
.next-btn {
  right: 0;
  font-size: 30px;
}

.product-link {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
}

.product-link-text {
  display: inline-block;
  padding: 10px 20px;
  background-color: red;
  color: #fff;
  text-decoration: none;
}

.product-link-text:hover {
  background-color: rgba(201, 64, 64, 0.826);
}