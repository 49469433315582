.nav-wrapper ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: whitesmoke;
  }
  
.nav-links li{
    float: left;
  }
  
.nav-links li a {
    display: block;
    color: black;
    font-weight: 630;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  

.nav-links li a:hover {
    background-color: #ff3535;
  }


.nav-wrapper img {
    width: 80px;
    aspect-ratio: 3/2;
    object-fit: contain;
    margin-left: 50px;
    margin-right: 20px;
}

.active{
  background-color: #ff3535;
}

  
  /*mobile mode*/
  .mobile_header{
    display:none;
  }
  
  @media only screen and (max-width: 768px){
    .nav-wrapper{
          display: none;
      }
  
    .mobile_header{
        display:block;
        width: 100vw;
        height: 60px;
        background-color: var(--nav-bg-color);
    }

    .mobile_header img{
          width: 80px;
          aspect-ratio: 3/2;
          object-fit: contain;
          margin-top: 10px;
          margin-left: 20px;
    }
  }
  
  
  