#header {
    color: white;
    text-align: center;
    margin-top: 15px;
  }

.value-wrapper{
    padding: 10px;
    margin: 10px;
    background-color: whitesmoke;
}
 
.value-text p {
    color: #626161;
 } 

.value-heading {
    font-size: 24px; 
    font-weight: bold;
  } 

.value-symbol {
    font-size: 36px; 
  }  
