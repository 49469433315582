body {
    font-family: 'Roboto', sans-serif;
  }
  
  h1, h2, h3 {
    font-family: 'Montserrat', sans-serif;
  }
  
  p {
    font-family: "Lato", Sans-serif;
  }