.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 15px;
  top: 15px
}
  
.bm-burger-bars {
  background: black;
}
  
.bm-cross-button {
  height: 34px;
  width: 34px;
}
  
.bm-cross {
  background: whitesmoke;
}
  
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0px;
}
  
.bm-menu {
  background-color: black;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: 100%;
}
  
.bm-item-list {
  padding: 1.0em;
}
  
.bm-item {
  display: block;
  margin-bottom: 1.2em;
  color: whitesmoke;
  text-decoration: none;
}
  
.burger-links li{
  list-style: none;
  width: 100%;
  border-radius: 5px;
}
  
/*hover animation*/
.burger-links li:hover {
  transition: 0.75s;
  --link-hover-color:whitesmoke ;
} 

.bm-item:hover {
  color:var(--font-color-secondary);
} 
  
/*Fixing bugs*/
.burger-links{
  padding-left: 0;
}