.footer-wrapper{
   background-color: whitesmoke;   /*#54595F */
   color: black;
}

.footer-wrapper img {
    width: 30%;
    aspect-ratio: 3/2;
    object-fit: contain;
}

.footer-wrapper ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
.footer-links li{
    float: left;
  }
  
.footer-links li a {
    display: block;
    color: black;
    text-align: justify;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .footer-links li h4 {
    text-align: justify;
    padding-left: 14px;
  }

  .footer-wrapper h4 {
    text-align: justify;
    padding-bottom: 14px;
  }

.footer-links li a:hover {
    background-color: red;
  }

.line{
    content: "";
    background-color: #d7d7d7;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
}

.social span{
  text-decoration: none;
  padding: 15px;
  justify-content: space-between;
  font-size: 23px;
  text-align: center;
  border-radius: 50%;
}

.social span:hover{
  color: white;
  background-color: black;  
}

@media only screen and (max-width: 768px){
    .footer-wrapper{
        text-align:justify;
      }
      .footer-links li{
        float: none;
      }
    
    }