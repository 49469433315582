.home-container {
    padding: 20px;
  }  

.mission-box{
  margin-top: 20px;
  padding: 20px;
  color: black;
  font-size: 34px;
  text-align: center;
  box-shadow: 0px -25px 20px -20px rgba(0,0,0,0.1), 0px 25px 20px -20px rgba(0,0,0,0.25);
}

  .stat-box {
    background-color: white; /* White background for stat boxes */
    padding: 10px;
    margin: 50px 0px 50px 0px;
    text-align: center;
    height: auto;
    width: 80%;
  }
  
  .symbol {
    font-size: 30px; /* Adjust the symbol size */
  }
  
  .count {
    font-size: 36px; /* Adjust the count size */
    font-weight: bold;
  }
  
  .text {
    font-size: 20px; /* Adjust the text size */
  }